import { Component, OnInit } from "@angular/core";
import { RestServiceService } from "../rest-service.service";
import { Offer } from "../model/offer";
import { SearchOfferResponse } from "../model/searchOfferResponse";
import { RatingObject } from "../model/ratingObject";
import { Router } from "@angular/router";

@Component({
  selector: "app-offers",
  templateUrl: "./offers.component.html",
  styleUrls: ["./offers.component.css"],
})
export class OffersComponent implements OnInit {
  constructor(private restService: RestServiceService, private router: Router){}
  results: SearchOfferResponse[];
  offer: Offer;

  modeList: boolean = true;
  modeRating: boolean = false;
  displayRatingPopin: boolean = false;
  displayErrorPopin: boolean = false;
  errorMessage: string = "";

  ngOnInit() {
    this.restService.getOffers().subscribe(
      (searchOfferResponse) => {
        this.results = searchOfferResponse;
        this.modeList = true;
        this.modeRating = false;
        console.log(searchOfferResponse);
      },
      (error) => {
        console.log("error when getting offers", error);
        if ((error.status == 403) || (error.status == 401)) {
            this.router.navigateByUrl("/login");
        }
        else{
            this.displayError("error when getting offers", true);
        }

      }
    );
  }

  displayError(msg: string, display: boolean) {
    if (display == true) {
      this.errorMessage = msg;
      this.displayRatingPopin = false;
      this.displayErrorPopin = true;
    } else {
      this.errorMessage = "";
      this.displayErrorPopin = false;
    }
  }

  diplayOffer(offerId) {
    this.displayErrorPopin = false;
    this.displayErrorPopin = false;

    for (let searchOfferResponse of this.results) {
      if (searchOfferResponse.current_offer.id == Number(offerId)) {
        this.offer = searchOfferResponse.current_offer;
        break;
      }
    }
    this.modeList = false;
    this.modeRating = true;
  }

  startRating() {
    this.restService.getOffers().subscribe(
      (searchOfferResponse) => {
        this.results = searchOfferResponse;
        if (this.results.length > 0) {
          this.offer = this.results[0].current_offer;
          this.modeList = false;
          this.modeRating = true;
        }
 
      },
      (error) => {
        console.log("error when getting offers", error);
        if ((error.status == 403) || (error.status == 401)) {
            this.router.navigateByUrl("/login");
        }
        else{
            this.displayError("error when getting offers", true);
        }
    
    }
    );
  }
  displayRating(value) {
    this.displayRatingPopin = value;
  }
  refreshRating() {
    this.restService.getOffers().subscribe(
      (searchOfferResponse) => {
        this.results = searchOfferResponse;
      },
      (error) => {
        console.log("error when getting offers", error);
        if ((error.status == 403) || (error.status == 401)) {
            this.router.navigateByUrl("/login");
        }
        else{
            this.displayError("error when getting offers", true);
        }
          }
    );
  }

  rateOffer(offerId) {
    for (let searchOfferResponse of this.results) {
      if (searchOfferResponse.current_offer.id == Number(offerId)) {
        this.offer = searchOfferResponse.current_offer;
        this.displayRating(true);
        break;
      }
    }
  }
  backToList() {
    this.refreshRating();
    this.modeList = true;
    this.modeRating = false;
    this.displayRatingPopin = false;
  }

  validateRating(offerId, value) {
    this.displayRatingPopin = false;
    let rating = new RatingObject();
    rating.rate = value;
    rating.rated = true;
    let date = new Date();
    rating.timestamp = date.toISOString();

    this.restService.postRating(rating, offerId).subscribe(
      (Any) => {
        for (let i = 0; i < this.results.length; i++) {
          if (this.results[i].current_offer.id == offerId) {
            let removed = this.results.splice(i, 1);
            break;
          }
        }
        console.log(this.results.length);
        if (this.results.length > 0) {
          this.offer = this.results[0].current_offer;
        } else {
          this.modeList = true;
          this.modeRating = false;
          this.offer = null;
        }
        window.scrollTo(0, 0);
      },
      (error) => {
        console.log("error when post offer rating", error);
        if ((error.status == 403) || (error.status == 401)) {
            this.router.navigateByUrl("/login");
        }
        else{
            this.displayError("error when post offer rating", true);
        }
      }
    );
  }
}
