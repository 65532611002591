import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SearchOfferResponse } from "./model/searchOfferResponse";
import { RatingObject } from "./model/ratingObject";
import { TokenResponse } from "./model/TokenResponse";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RestServiceService {
  constructor(private http: HttpClient,  private router: Router) {}

  private token: String = null;

  private offersEndpoint: String = "offers";
  private authEndpoint = "auth";

    public setToken(token: String) {
        this.token = token;
    }

    public getToken(login: String, pwd: String) {
        let param: String = "/user/token?" + "login=" + login + "&pwd=" + pwd;
        return this.http.get<TokenResponse>(this.authEndpoint + param);
    }
  
  public getOffers() {
    if (this.token == null){
        this.router.navigateByUrl("/login");
        console.log("auth tocken does not exist - routing to login")
    }
    else{
        let headers = new HttpHeaders().set("Content-Type", "application/json");
        headers = headers.append("Offer-Authorization", this.token.toString());

        return this.http.get<SearchOfferResponse[]>(
        this.offersEndpoint + "/offers",
        {
            headers,
        }
        );
    }
  }
  public postRating(ratingObject: RatingObject, offerId: number) {

    if (this.token == null){
        this.router.navigateByUrl("/login");
        console.log("auth tocken does not exist - routing to login")
    }
    else{
        let headers = new HttpHeaders().set("Content-Type", "application/json");
        headers = headers.append("Offer-Authorization", this.token.toString());
        let body: String = JSON.stringify(ratingObject);
        
        return this.http.post<SearchOfferResponse[]>(
        this.offersEndpoint + "/offer/" + offerId.toString() + "/rating/",
        body,
        {
            headers,
        }
        );
    }
  }
}
