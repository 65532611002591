import { Component, OnInit } from "@angular/core";
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators
} from "@angular/forms";
import { RestServiceService } from "../rest-service.service";
import { Router } from "@angular/router";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loginResutErrorMessage: String;
    isLogged: boolean;

    constructor(
        private router: Router,
        private restService: RestServiceService,
        private fb: FormBuilder
    ) {
        this.createForm();
    }
    ngOnInit() {}

    createForm() {
        this.loginForm = this.fb.group({
            login: ["", Validators.required],
            password: ["", Validators.required]
        });
    }
    onClickSubmit() {
        let login: String = this.loginForm.controls.login.value;
        let pwd: String = this.loginForm.controls.password.value;

        this.restService.getToken(login, pwd).subscribe(
            tokenResponse => {
                let token: String = tokenResponse.token;
                console.log("token = " + token);
                this.restService.setToken(token);
                this.isLogged = true;
                this.router.navigateByUrl("/home");
            },
            error => {
                console.log("error when getting client token", error);
                this.isLogged = false;
                this.loginResutErrorMessage =
                    error.status + " : Error could not log on.";
            }
        );
    }
}
